import { DefaultFilterComponent, DefaultFormComponent, FormOptions } from '@hutsix/ngxh6';
import { UserFormComponent } from '../modules/_shared/components/forms/custom-forms/user-form/user-form.component';
import { CourseSessionFormComponent } from '../modules/_shared/components/forms/custom-forms/course-session-form/course-session-form.component';
import { ReferralFormComponent } from '../modules/_shared/components/forms/custom-forms/referral-form/referral-form.component';
import forms_conf from '../_generated/ext_config/forms.json';

export const custom_filters: { [key: string]: any } = {
  DefaultFilterComponent: DefaultFilterComponent,
};

export const custom_forms: { [key: string]: any } = {
  DefaultFormEmbedComponent: DefaultFormComponent,
  UserFormComponent: UserFormComponent,
  CourseSessionFormComponent: CourseSessionFormComponent,
  ReferralFormComponent: ReferralFormComponent,
};

/** Override generated form values here */
const forms_conf_overrides: Override = {
  user: {
    route: 'crm/form/user',
    object_name: 'User',
    api_get: '/api/user/form',
    api_refresh_endpoint: '/api/v1/users',
    success_route: 'crm/users/{id}',
    component: 'UserFormComponent',
  },
  message: {
    object_name: 'Message',
    cache_strategy: 'performance',
    object_class: 'App\\Entity\\Message',
    allow_edits: false,
    create_success_string: 'Message Sent!',
  },
  notes: {
    object_name: 'Note',
    api_get: '/api/notes/form',
    api_refresh_endpoint: '/api/v1/note_lists',
    success_route: null,
  },
  // profile{
  //   object_name: 'Profile',
  //   route: 'profile',
  //   api_get: '/api/user/profile',
  //   create_string: 'Edit Profile',
  //   success_route: 'profile',
  //   display: 'horizontal-wide',
  // },
  organisation: {
    object_name: 'Organisation',
    object_class: 'App\\Entity\\Organisation',
    allow_archive: false,
    display: 'horizontal-wide',
    modal_display: 'horizontal',
    modal_size: 'lg',
  },
  staff: {
    route: 'account/form/staff',
    object_name: 'User',
    api_get: '/api/staff/form',
    api_refresh_endpoint: '/api/v1/users',
    success_route: 'account/staff',
    display: 'horizontal-wide',
  },
  course_spec: {
    object_class: 'App\\Entity\\CourseSpec',
    object_name: 'Course Type',
    api_refresh_endpoint: '/api/v1/course_specs',
    display: 'horizontal-wide',
    modal_display: 'horizontal-wide',
  },
  course_session: {
    object_class: 'App\\Entity\\CourseSession',
    object_name: 'Course',
    api_refresh_endpoint: ['/api/v1/course_sessions', '/api/v1/course_sessions/upcoming', '/api/v1/course_sessions/past'],
    display: 'horizontal-wide',
    modal_display: 'horizontal',
    component: 'CourseSessionFormComponent',
  },
  course_participant: {
    api_get: '/api/course/participant',
    object_name: 'Course Participants',
    modal_size: 'lg',
    edit_string: 'Add Course Participants',
    edit_success_string: 'Participants added',
  },
  register_individual: {
    api_get: '/api/course/register_individual',
    object_name: 'Registration',
  },
  register_organisation: {
    api_get: '/api/course/register_organisation',
    object_name: 'Registration',
  },
  referral: {
    object_name: 'Referral',
    api_post: '/api/referral/form',
    offline_edit: true,
    offline_create: true,
    allow_archive: true,
    object_class: 'App\\Entity\\Referral',
    component: 'ReferralFormComponent',
    create_success_string: 'Referral Sent',
  },
  decision_tree: {
    object_class: 'App\\Entity\\DecisionTree',
    object_name: 'Decision Tree',
    api_refresh_endpoint: '/api/v1/decision_trees',
    display: 'horizontal',
    modal_display: 'horizontal',
  },
  decision_tree_item: {
    object_class: 'App\\Entity\\DecisionTreeItem',
    object_name: 'Decision Tree Item',
    api_refresh_endpoint: '/api/v1/decision_trees_items',
    display: 'horizontal-wide',
    modal_display: 'horizontal-wide',
    modal_size: 'xl',
    allow_archive: true,
  },
  self_service_referral: {
    api_get: '/api/self-service-referral/form',
    api_post: '/api/self-service-referral/form',
    create_success_string: 'Referral Sent',
  },
};

/** Create custom forms or override generated forms here */
const forms = {
  // example_test: new FormOptions({
  //   slug: 'profile',
  //   object_name: 'Example',
  //   display: 'horizontal-wide',
  //   create_string: 'Profile',
  // }),
};

/**
 *
 * You shouldn't need to change anything below
 *
 */
export function getFormsConfig(): { [key: string]: FormOptions } {
  const f = JSON.parse(JSON.stringify(forms_conf));
  for (const [k, v] of Object.entries(forms_conf_overrides)) {
    if (f[k]) {
      f[k] = Object.assign(forms_conf[k], v);
    } else {
      console.warn(`Attempt to override generated form failed. Key '${k}' doesn't exist`);
    }
  }
  return { ...f, ...forms };
}

export function getForm(slug: FormKeys): FormOptions {
  return getFormsConfig()[slug];
}

declare global {
  type FormKeys = keyof typeof forms | keyof typeof forms_conf;
}

type Override = { [Property in keyof Partial<typeof forms_conf>]: Partial<FormOptions> };
