import cruds_conf from '../_generated/ext_config/cruds.json';
import { CrudOptions } from '@hutsix/ngxh6';

/** Override generated CRUD values here */
const cruds_conf_overrides: Override = {
  messages: {
    objectLabel: 'Message',
    icon: 'fa-comments-o',
    // formSlug: 'message-recipient',
    apiEndpoint: '/api/v1/messages',
    queryKey: 'env',
    apiClassName: 'App\\Entity\\Message',
    archivable: false,
    searchable: true,
    // filterable: true,
    tableSpec: {
      hideActions: true,
      columns: [
        { header: 'Contact', value: 'recipient', type: 'usericon', style: { 'min-width': '50px' } },
        {
          header: 'Date',
          value: 'created',
          type: 'date',
          typeOption: 'short',
          orderBy: 'created',
          style: { 'min-width': '150px' },
          class: 'text-nowrap',
        },
        { header: 'Direction', value: 'direction', style: { 'min-width': '150px' } },
        { header: 'Method', value: 'method', style: { 'min-width': '150px' } },
        {
          header: 'Message',
          value: 'messageContent',
          style: { 'min-width': '150px', 'width': '100%' },
        },
      ],
    },
  },
  organisations: {
    objectLabel: 'Organisation',
    // formSlug: 'organisations',
    icon: 'fa-sitemap',
    apiEndpoint: '/api/v1/organisations',
    queryKey: 'org',
    apiClassName: 'App\\Entity\\Organisation',
    archivable: false,
    searchable: true,
    filterable: false,
    sortable: false,
    tableSpec: {
      actionUrl: './',
      columns: [
        { header: 'Name', value: 'name', style: { width: '100%' }, linkAction: true },
        { header: 'Phone', value: 'phone', class: 'text-nowrap' },
        { header: 'Address', value: 'address', style: { 'min-width': '300px' } },
      ],
    },
  },
  course_sessions: {
    objectLabel: 'Course',
    // formSlug: 'course_session',
    apiEndpoint: '/api/v1/course_sessions',
    queryKey: 'cs',
    apiClassName: 'App\\Entity\\CourseSession',
    archivable: false,
    searchable: false,
    // filterable: true,
    tableSpec: {
      hideActions: false,
      columns: [
        { header: 'Type', type: 'objvalue', value: 'type', typeOption: 'name', orderBy: 'type.name', style: { width: '100%' } },
        { header: 'Date', type: 'date', value: 'date', orderBy: 'date', class: 'text-nowrap' },
        { header: 'Start', type: 'date', typeOption: 'shortTime', value: 'start', class: 'text-nowrap' },
        { header: 'Finish', type: 'date', typeOption: 'shortTime', value: 'end', class: 'text-nowrap' },
        { header: 'In Person', type: 'boolean', value: 'inPerson', class: 'text-nowrap' },
        { header: 'Remote', type: 'boolean', value: 'remote', class: 'text-nowrap' },
      ],
    },
  },
};

/** Create custom CRUDS or override generated forms here */
const cruds = {
  // test: new CrudOptions({
  //   objectLabel: 'Test',
  //   apiEndpoint: '/api/v1/examples',
  // }),
};

/**
 *
 * You shouldn't need to change anything below
 *
 */
export function getCrudsConfig() {
  const c = JSON.parse(JSON.stringify(cruds_conf));
  for (const [k, v] of Object.entries(cruds_conf_overrides)) {
    if (c[k]) {
      c[k] = Object.assign(cruds_conf[k] || {}, v);
    } else {
      console.warn(`Attempt to override generated CRUD failed. Key '${k}' doesn't exist`);
    }
  }
  return { ...c, ...cruds };
}

export function getCrud(slug: CrudKeys): CrudOptions {
  return getCrudsConfig()[slug];
}

declare global {
  type CrudKeys = keyof typeof cruds | keyof typeof cruds_conf;
}

type Override = { [Property in keyof Partial<typeof cruds_conf>]: Partial<CrudOptions> };
