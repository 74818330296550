import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfFormDateComponent } from './components/forms/custom-fields/sf-form-date/sf-form-date.component';
import { NgPipesModule } from 'ngx-pipes';
import { FormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToastrModule } from 'ngx-toastr';
import { DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule } from 'ngx-dropzone-wrapper';
import { SfFormFileuploadComponent } from './components/forms/custom-fields/sf-form-fileupload/sf-form-fileupload.component';
import { SfFormSignatureComponent } from './components/forms/custom-fields/sf-form-signature/sf-form-signature.component';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { MomentModule } from 'ngx-moment';
import { SfFormSelectImagegridComponent } from './components/forms/custom-fields/sf-form-select-imagegrid/sf-form-select-imagegrid.component';
import { UserFormComponent } from './components/forms/custom-forms/user-form/user-form.component';
import { SfFormDatetimeComponent } from './components/forms/custom-fields/sf-form-datetime/sf-form-datetime.component';
import { SfFormTimeComponent } from './components/forms/custom-fields/sf-form-time/sf-form-time.component';
import { Ngxh6Module, SfFormModule } from '@hutsix/ngxh6';
import { SfFormSelectIconComponent } from './components/forms/custom-fields/sf-form-select-icon/sf-form-select-icon.component';
import { NavbarComponent } from './components/ui/navbar/navbar.component';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { WebsiteContentComponent } from './components/ui/website-content/website-content.component';
import { IframeAutoHeightDirective } from './directives/iframe-auto-height.directive';
import { CourseSessionFormComponent } from './components/forms/custom-forms/course-session-form/course-session-form.component';
import { UserCoursesComponent } from './components/ui/user-courses/user-courses.component';
import { CourseDetailsComponent } from './routes/course-details/course-details.component';
import { ReferralFormComponent } from './components/forms/custom-forms/referral-form/referral-form.component';
import { SfFormFileuploadCollectionComponent } from './components/forms/custom-fields/sf-form-fileupload-collection/sf-form-fileupload-collection.component';
import { QuizComponent } from './routes/quiz/quiz.component';
import { SelfServiceReferralComponent } from './routes/self-service-referral/self-service-referral.component';
import { StartPageComponent } from './routes/start-page/start-page.component';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    // Change this to your upload POST address:
    url: '#',
    maxFilesize: 50,
    // acceptedFiles: '',
    maxFiles: 1,
    addRemoveLinks: true,
    autoQueue: false,
    autoProcessQueue: false,
    uploadMultiple: false,
    headers: {
        'Cache-Control': '',
        'X-Requested-With': '',
    },
    method: 'post',
};

@NgModule({
    declarations: [
        SfFormDateComponent,
        SfFormFileuploadComponent,
        SfFormSignatureComponent,
        SfFormSelectImagegridComponent,
        SfFormDatetimeComponent,
        SfFormTimeComponent,
        SfFormSelectIconComponent,
        SfFormFileuploadCollectionComponent,
        UserFormComponent,
        CourseSessionFormComponent,
        NavbarComponent,
        WebsiteContentComponent,
        IframeAutoHeightDirective,
        UserCoursesComponent,
        CourseDetailsComponent,
        ReferralFormComponent,
        QuizComponent,
        SelfServiceReferralComponent,
        StartPageComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,

        // To be exported
        Ngxh6Module,
        NgPipesModule,
        QuillModule,
        NgbModule,
        SfFormModule.forRoot({
            customTypes: [
                { prefix: 'date', component: SfFormDateComponent },
                { prefix: 'datetime', component: SfFormDatetimeComponent },
                { prefix: 'time', component: SfFormTimeComponent },
                { prefix: 'file_upload', component: SfFormFileuploadComponent },
                { prefix: 'signature', component: SfFormSignatureComponent },
                { prefix: 'consent_form_terms', component: SfFormSignatureComponent },
                { prefix: 'imagegrid', component: SfFormSelectImagegridComponent },
                { prefix: ['choice', 'select_icon'], component: SfFormSelectIconComponent },
                {
                    prefix: 'fileupload-collection',
                    component: SfFormFileuploadCollectionComponent,
                    rowClasses: 'col-12',
                },
            ],
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
            preventDuplicates: true,
            countDuplicates: true,
            resetTimeoutOnDuplicate: true,
            newestOnTop: false,
            enableHtml: false,
            // timeOut: 1000,
        }),
        VirtualScrollerModule,
        DragDropModule,
        MomentModule,
        DropzoneModule,
        AngularSignaturePadModule,
        MomentModule.forRoot({
            relativeTimeThresholdOptions: {
                s: 60,
                m: 60,
                h: 24,
                d: 7,
                w: 4,
                M: 12,
            },
        }),
    ],
    exports: [
        // Declared
        SfFormDateComponent,
        SfFormDatetimeComponent,
        SfFormTimeComponent,
        SfFormFileuploadComponent,
        SfFormSignatureComponent,
        SfFormSelectImagegridComponent,
        SfFormSelectIconComponent,
        NavbarComponent,
        WebsiteContentComponent,
        IframeAutoHeightDirective,
        UserCoursesComponent,
        CourseDetailsComponent,

        // IMPORTS:
        Ngxh6Module,
        NgPipesModule,
        MomentModule,
        QuillModule,
        NgbModule,
        SfFormModule,
        VirtualScrollerModule,
        DragDropModule,
        ToastrModule,
        ToastrModule,
        MomentModule,
    ],
    providers: [
        {
            provide: DROPZONE_CONFIG,
            useValue: DEFAULT_DROPZONE_CONFIG,
        },
    ],
})
export class SharedModule {}
