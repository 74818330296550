<div #ddm="ngbDropdown" ngbDropdown placement="bottom-right" (openChange)="openChange($event)">
    <div class="input-group">
        <div
            class="form-control"
            (click)="$event.preventDefault(); ddm.open()"
            [class.ng-invalid]="!ngControl.valid"
            [class.ng-valid]="ngControl.valid"
            [class.ng-dirty]="!ngControl.pristine"
            [class.ng-pristine]="ngControl.pristine"
            [class.ng-touched]="ngControl.touched"
            [class.ng-untouched]="!ngControl.touched"
            [class.errors]="view.vars?.errors?.length"
        >
            {{ inputValue ? ('0001-01-01T' + inputValue | str2Date | date: 'h:mm a') : '' }}
        </div>

        <div class="input-group-append" *ngIf="!view.vars.disabled">
            <button class="btn btn-outline-gray no-caret" ngbDropdownToggle type="button">
                <i class="fa fa-clock-o"></i>
            </button>
        </div>
    </div>

    <div class="custom-calendar" ngbDropdownMenu *ngIf="!view.vars.disabled">
        <div class="inner">
            <div class="top p-2 px-3 border-bottom-1 d-lg-none">
                <span class="text-muted m-0">{{ view.vars.label || view.vars.name | ucfirst }}</span>
                <span class="text-muted float-right">Select time</span>
            </div>

            <div class="mx-2 d-flex align-items-center justify-content-center">
                <ngb-timepicker [(ngModel)]="timeValue" [minuteStep]="5" [spinners]="true" [meridian]="true"></ngb-timepicker>
            </div>

            <div class="d-flex actions p-2">
                <div class="btn btn-outline-danger" (click)="clear()">Clear</div>
                <div class="btn-group ms-auto">
                    <div class="btn btn-outline-gray" (click)="selectNow(); ddm.close()">Now</div>
                    <div class="btn btn-gray" (click)="ddm.close()">Done</div>
                </div>
            </div>
        </div>
    </div>
</div>
